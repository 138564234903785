<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel"  >
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
      <el-form-item label="logo" prop="logoId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z2.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      
       <el-form-item label="分类类型" prop="typeId">
          <el-select
            v-model="formData.typeId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布人名称" prop="publishUserName">
          <el-input
            v-model="formData.publishUserName"
            placeholder="请输入发布人名称"
            style="width: 50%"
          />
        </el-form-item>
<el-form-item label="来源类型" prop="sourceType" v-if="!formData.articleId">
       <el-radio v-model="formData.sourceType" :label='1' @change="change">本地</el-radio>
       <el-radio v-model="formData.sourceType" :label="2" @change="change">外部链接</el-radio>
    
      </el-form-item>
      <el-form-item label="资讯详情" prop="content"  v-if="formData.sourceType==1">
          <div style="line-height: normal">
            <uEditor v-model="formData.content"></uEditor>
          </div>
        </el-form-item>
         <el-form-item label="外部链接" prop="urlMsg" v-if="formData.sourceType==2">
          <el-input v-model="formData.urlMsg" style="width: 30%" />
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.articleId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  <el-dialog title="添加资讯分类" :visible="dialog" @close="btnCancel1">
      <!-- 表单 -->
      <el-form
        ref="pForm"
        :model="fdata"
        :rules="rules1"
        label-width="200px">
        <el-form-item label="标题名称" prop="typeName">
          <el-input
            v-model="fdata.typeName"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
        
      </el-row>
      
    </el-dialog>
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 60%">
            <el-select
              v-model="typeId"
              placeholder="请选择资讯分类"
              @change="selectChange2"
              style="wdith: 300px; margin-right: 10px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchtitle"
              placeholder="请输入标题名称"
              style="width: 50%"
            ></el-input>
            <el-button
              type="primary"
              slot="append"
              @click="searchgetArticles"
              style="margin-left: 5px"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="12" align="right">
           <el-button class="el-icon-edit" type="warning" @click="addfl"
            >管理资讯分类</el-button
          >
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加资讯内容</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="logoId"
          label="logo"
          width="200"
        >
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.logoId"
                :preview-src-list="[
                  'http://img.c-fol.cn/' + scope.row.logoId,
                ]"
              />
            </div>
          </template>
        </el-table-column>
      
        <el-table-column
          align="center"
          prop="title"
          label="标题"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="publishUserName"
          label="发布人"
          :show-overflow-tooltip="true"
        />

        <el-table-column
          align="center"
          prop="sourceType"
          label="来源类型"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.sourceType==1?'本地':'外部链接' }}
          </template>
        </el-table-column>
       
        <el-table-column
          align="center"
          prop="typeName"
          label="分类名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="viewNum"
          label="浏览数"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="collectNum"
          label="收藏数量"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      
       
        <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
         
            <el-button
              type="text"
              size="small"
              @click="setArticleStatus(scope.row)"
            >
              {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>

            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delArticle(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getArticles"
      />
    </div>
  </div>
</template>

<script>
import {
  addType,
  getUploadToken,
  getTypes,
  getArticles,
  addArticle,
  setArticleStatus,
  modifyArticle,
  delArticle,
} from "../../request/http";

export default {
  data() {
    return {
      dialog: false,
      fdata: {
        typeName: "",
        typeGroup: "ARTICLE",
      },
      imageUrl: "",
      typeId: "",
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: "", // 列表
      options: [],
      total: 0, //总条数
      postData: {
        token: "",
        key: "", //上传的Logo
      },
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formData: {
        logoId: "",
        typeId: "",
        publishUserName: "",
        sourceType: null,
        title: "",
        content: "",
        urlMsg: "",
      },
      searchtitle: "", //搜索名称
      rules: {
        title: [
          { required: true, message: "标题名称不能为空", trigger: "blur" },
        ],
        logoId: [{ required: true, message: "logo不能为空", trigger: "blur" }],
        publishUserName: [
          { required: true, message: "发布人名称不能为空", trigger: "blur" },
        ],
        sourceType: [
          { required: true, message: "来源类型不能为空", trigger: "blur" },
        ],
        content: [
          { required: true, message: "资讯详情不能为空", trigger: "blur" },
        ],
        urlMsg: [
          { required: true, message: "外部链接不能为空", trigger: "blur" },
        ],
        typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
        sort: [{ required: true, message: "排序不能为空", trigger: "blur" }],
      },
      rules1: {
        typeName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getArticles();
    this.getList();
  },
  methods: {
     addfl(){
          this.$router.push({
        path: "/managementarticle",
       
      });
     },
     btnOK1() {
      this.$refs.pForm
        .validate()
        .then(() => {
            return addType(this.fdata);
        })
        .then(() => {
          //  提示消息
            this.$message.success("添加分类成功");
            this.getList();
         

          this.dialog = false;
        });
    },
 btnCancel1() {
     
      this.fdata = {};
      this.$refs.pForm.resetFields();
      this.dialog =false
    },
    async getList() {
      let res = await getTypes({
        typeGroup: "ARTICLE",
      });
      console.log(res);

      this.options = res.data;
    },

    async selectChange2(val) {
      this.queryParams.typeId = val;
      let { data, count } = await getArticles(this.queryParams);
      this.list = data;
      this.total = count;
    },
    selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    async change(val) {
      console.log(val);
      this.formData.sourceType = Number(val);
    },
    async setArticleStatus(row) {
      let id = row.articleId;
      let res = await setArticleStatus({ articleId: id });
      console.log(res.code);
      res.code == 0 &&
        this.$message({
          type: "success",
          message: "设置成功",
        });
      this.getArticles();
    },
    //获取列表
    async getArticles() {
      let { data, count } = await getArticles(this.queryParams);
      this.list = data;
      this.total = count;
      console.log(this.list);
      this.loading = false;
    },
    //查询
    async searchgetArticles() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        title: this.searchtitle,
      };

      let { data, count } = await getArticles(this.queryParams);
      this.list = data;
      this.total = count;
    },
    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.logoId = res.key;
    },
    async beforeAvatarUpload(file) {

      console.log(file);
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },

    // 打开添加的窗口
    addSchoolInfo() {
        this.editOradd = 0;
      let editOradd = this.editOradd;
      this.$router.push({
        path: "/addaboutus",
        query: { editOradd },
      });
      // this.imageUrl=null

      // this.title = "添加资讯信息";
      // this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
        this.editOradd = 1;
      let editOradd = this.editOradd;
      let obj = val;
      this.$router.push({
        path: "/addaboutus",
        query: { editOradd, obj },
      });
      // console.log(val);
      // this.imageUrl = "http://img.c-fol.cn/" + val.logoId;
      // this.title = "编辑资讯信息";
      // this.formData = {
      //   articleId: val.articleId,
      //   logoId: val.logoId,
      //   typeId: val.typeId,
      //   publishUserName: val.publishUserName,
      //   sourceType: val.sourceType,
      //   title: val.title,
      //   content: val.content,
      //   urlMsg: val.urlMsg,
      //   sort: val.sort,
      // };
      // this.showDialog = true;
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.articleId) {
            this.formData.sort = Number(this.formData.sort);
            return modifyArticle(this.formData);
          } else {
            return addArticle(this.formData);
          }
        })
        .then(() => {
          //  提示消息
          if (this.formData.articleId) {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新增成功");
          }

          this.getArticles();

          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {
      this.imageUrl = "";
      this.formData = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除信息
    async delArticle(row) {
      try {
        await this.$confirm("确定要删除该资讯吗");
        await delArticle({ articleId: row.articleId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getArticles();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>